import React, { useContext, useRef, useState } from 'react';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import Slider from 'react-slick';
import VideoPlayer from '../VideoPlayer';
import { setOverlay } from '../../utils/SspProvider/dispatchFunctions';
import { OverlayEnum } from '../../enums';
import i18next from 'i18next';
import SspProviderContext from '../../utils/SspProvider/context';
import { OverlayType } from './interfaces';

let activeVideoId: string | null = null;

interface SlickSlider extends Slider {
    slickGoTo: (index: number) => void;
}

interface ImageGalleryProps {
    currentOverlayImages: Array<SSPContentVO> | undefined;
    slide: SSPContentVO;
    currentOverlay: OverlayType;
    index: number;
}

function MultiSlideOverlay(props: ImageGalleryProps) {
    const store = useContext(SspProviderContext);
    const [selectedOverlayImage, setSelectedOverlayImage] = useState(0);
    const overlaySliderRef = useRef<SlickSlider | null>(null);
    const handleClick = (index: number) => {
        if (overlaySliderRef.current) {
            overlaySliderRef.current.slickGoTo(index);
            setSelectedOverlayImage(index);
        }
    };

    return (
        <div key={props.index}>
            <h5>{props.slide.asModel.str('Name')}</h5>
            {props.slide.asModel.str('DescriptionUpper') && <p>{props.slide.asModel.str('DescriptionUpper')}</p>}
            {props.slide.asModel.reflist('Assets') && (
                <Slider
                    fade={true}
                    adaptiveHeight={true}
                    ref={overlaySliderRef}
                    beforeChange={(current, next) => {
                        setSelectedOverlayImage(next);
                        if (
                            props.slide.asModel.reflist('Assets') &&
                            props.slide.asModel.reflist('Assets')[current].asAsset.fileFamily === 'video'
                        ) {
                            activeVideoId = `overlay-video-${next}`;
                            window.dispatchEvent(new CustomEvent('inner-slider-swipe'));
                        } else {
                            window.dispatchEvent(new CustomEvent('inner-slider-swipe'));
                        }
                    }}
                >
                    {props.slide.asModel.reflist('Assets').map((img, index) =>
                        img.asAsset.fileFamily === 'video' ? (
                            <div className='large' key={`large-video- ${index}`}>
                                <VideoPlayer
                                    videoId={`overlay-video-${index}`}
                                    filetype={img.asAsset.fileProperties.fileType}
                                    link={img.asAsset.url}
                                    placeholder={img.asAsset.posterUrl}
                                    autoPlay={selectedOverlayImage === index}
                                />
                            </div>
                        ) : (
                            <img className='large' src={img.asAsset.url} key={`large-img- ${index}`} alt={img.name} />
                        )
                    )}
                </Slider>
            )}
            <div className='img-gallery'>
                {props.slide.asModel.reflist('Assets') &&
                    props.slide.asModel.reflist('Assets').map((img, index) =>
                        img.asAsset.fileFamily === 'video' ? (
                            <div
                                className='video-container'
                                key={`img-gallery- ${index}`}
                                onClick={() => handleClick(index)}
                            >
                                <img
                                    className={`${selectedOverlayImage === index ? 'active' : ''}`}
                                    src={img.asAsset.posterUrl}
                                    alt={img.name}
                                />
                                <svg>
                                    <use xlinkHref={'#play'} />
                                </svg>
                            </div>
                        ) : (
                            <div
                                className='img-container'
                                key={`img-gallery- ${index}`}
                                onClick={() => handleClick(index)}
                            >
                                <img
                                    className={`${selectedOverlayImage === index ? 'active' : ''}`}
                                    src={img.asAsset.url}
                                    alt={img.name}
                                />
                            </div>
                        )
                    )}
            </div>

            {props.slide.asModel.str('DescriptionBottom') && <p>{props.slide.asModel.str('DescriptionBottom')}</p>}
            {props.slide.asModel.str('Url') && (
                <button
                    className='blue'
                    onClick={() => {
                        setOverlay(store, {
                            enum: OverlayEnum.ExternalContent,
                            media: props.slide.asModel.str('Url'),
                        });
                    }}
                >
                    {i18next.t('LearnMore')}
                </button>
            )}
        </div>
    );
}

export default MultiSlideOverlay;
